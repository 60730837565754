
import { defineComponent } from "vue-demi";
import Header from "./header.vue";
import CommonQuestionnaireTemplateUsers from "../common/users.vue";
import CommonQuestionnaireTemplateTarget from "../common/target.vue";

export default defineComponent({
  name: "FirstQuestionnaireTemplate",
  props: ["expert", "student", "targets"],
  components: {
    Header,
    CommonQuestionnaireTemplateUsers,
    CommonQuestionnaireTemplateTarget,
  },
});
